import './App.css';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { Route } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Portal from "./components/Portal";

function App() {


  const oktaAuth = new OktaAuth({
    issuer: process.env['REACT_APP_OKTA_ISUSER'],
    clientId: process.env['REACT_APP_OKTA_CLIENT_ID'],
    redirectUri: `${window.location.origin}/callback`,
    scopes: ['openid', 'profile', 'email'],
  });

  function restoreOriginalUri(oktaAuth: OktaAuth, originalUri: string) {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute path="/" exact={true}>
        <Portal />
      </SecureRoute>
      <Route path="/callback" component={LoginCallback} />
    </Security>
  );
}

export default App;
