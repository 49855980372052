import React from "react";
import Container from '@mui/material/Container';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Services from "./Services";
import Connect from "./Connect";
import FileSearch from "./FileSearch";
import Introduction from "./Introduction";
import { useOktaAuth } from '@okta/okta-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import ShareIcon from '@mui/icons-material/Share';
import MenuBookIcon from '@mui/icons-material/MenuBook';

interface UrlList {
  [fileName: string]: { url: string }
}

const Contents = () => {
  const [tabId, setTabId] = React.useState('intro');
  const [urlList, setUrlList] = React.useState<UrlList>({});
  const { authState } = useOktaAuth();

  const handleTabChange = (event: React.ChangeEvent<{}>, tabId: string) => {
    setTabId(tabId);
  };

  React.useEffect(() => {
    const retrieveThumbnails = async () => {
      if (authState?.isAuthenticated) {
        const response = await fetch(`https://gzi7fmtuzb.execute-api.ap-northeast-1.amazonaws.com/thumbnails`, {
          headers: {
            Authorization: `Bearer ${authState?.idToken?.idToken}`
          }
        });
        setUrlList(await response.json());
      }
    }
    retrieveThumbnails();
  }, [authState]);

  const PcUi = () => {
    const pcStyle = {
      modal: {
        bgcolor: 'background.paper',
        boxShadow: 24,
        maxWidth: 600,
        p: 4,
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    };
    return (
      <Container fixed>
        <Tabs value={tabId} onChange={handleTabChange} aria-label='basic tabs example'>
          <Tab label='intro' value="intro" />
          <Tab label='connect' value="connect" />
          <Tab label='services' value="services" />
          <Tab label='filesearch' value="filesearch" />
        </Tabs>
        <div style={{ marginTop: '10px' }}>
          {tabId === 'intro' ? <div data-testid='connect-tab-contents' ><Introduction /></div> : null}
          {tabId === 'connect' ? <div data-testid='connect-tab-contents' ><Connect style={pcStyle} /></div> : null}
          {tabId === 'services' ? <div data-testid='services-tab-contents' ><Services urlList={urlList} /></div> : null}
          {tabId === 'filesearch' ? <div data-testid='filesearch-tab-contents' ><FileSearch /></div> : null}
        </div>
      </Container>
    );
  }

  const MobileUi = () => {
    const mobileStyle = {
      modal: {
        bgcolor: 'background.paper',
        boxShadow: 24,
        maxWidth: 600,
        p: 4,
      }
    };
    return (

      <Container fixed>
        <div style={{ marginTop: '10px' }}>
          {tabId === 'intro' ? <div data-testid='connect-tab-contents' ><Introduction /></div> : null}
          {tabId === 'connect' ? <div data-testid='connect-tab-contents' ><Connect style={mobileStyle} /></div> : null}
          {tabId === 'services' ? <div data-testid='services-tab-contents' ><Services urlList={urlList} /></div> : null}
          {tabId === 'filesearch' ? <div data-testid='filesearch-tab-contents' ><FileSearch /></div> : null}
        </div>
        <BottomNavigation
          showLabels
          value={tabId}
          onChange={handleTabChange}
          sx={{ position: 'fixed', bottom: 0, width: 1.0 }}
        >
          <BottomNavigationAction label="intro" value="intro" icon={<InfoIcon />} />
          <BottomNavigationAction label="connect" value="connect" icon={<ShareIcon />} />
          <BottomNavigationAction label="services" value="services" icon={<MenuBookIcon />} />
          <BottomNavigationAction label="filesearch" value="filesearch" icon={<DescriptionIcon />} />
        </BottomNavigation>
      </Container>
    );
  }
  const matches: boolean = useMediaQuery("(min-width:577px)");

  if (matches) {
    return <PcUi />;
  }
  return <MobileUi />;
};

export default Contents;

