import Graph from "../domain/Graph"

function ConvertToGraph(label: any, graph: Graph, element: any) {
    switch (label) {
        case 'Employee':
            graph.nodes.push(
                {
                    type: element.labels[0],
                    id: element.elementId,
                    label: element.properties.name,
                    color: "#e6e6fa",
                    name: element.properties.name,
                    image_path: "/person_default.png",
                    grade: element.properties.grade,
                    employee_no: element.properties.employee_no,
                    work_location: element.properties.work_location,
                }
            )
            break;
        case 'Organization':
            graph.nodes.push(
                {
                    type: element.labels[0],
                    id: element.elementId,
                    label: element.properties.name,
                    color: "#FF8888",
                    name: element.properties.name,
                    image_path: "/team_default.png"

                })
            break;
        case 'System':
            graph.nodes.push(
                {
                    type: element.labels[0],
                    id: element.elementId,
                    label: element.properties.name,
                    color: "#CCFFCC",
                    name: element.properties.name,
                    image_path: "/team_default.png",
                    bu_name: element.properties.bu_name,
                    client_company: element.properties.client_company,
                    node_name: element.properties.node_name,
                    client_name_1: element.properties.client_name_1,
                    client_name_2: element.properties.client_name_2,
                    client_name_3: element.properties.client_name_3,
                    client_department: element.properties.client_department,
                    ap_code: element.properties.ap_code,
                    remarks: element.properties.remarks,
                    maintenance_start_date: element.properties.maintenance_start_date,
                    maintenance_end_date: element.properties.maintenance_end_date,
                    release_start_date: element.properties.release_start_date,
                    release_end_date: element.properties.release_end_date,
                }
            )
            break;
        case 'Project':
            graph.nodes.push(
                {
                    type: element.labels[0],
                    id: element.elementId,
                    label: element.properties.name,
                    color: "#fffacd",
                    name: element.properties.name,
                    image_path: "/team_default.png",
                    code: element.properties.code,
                    business_type: element.properties.business_type,
                    supervising_org_code: element.properties.supervising_org_code,
                    supervising_org_name: element.properties.supervising_org_name,
                    abbreviation: element.properties.abbreviation,
                    client_name: element.properties.client_name,
                    client_code: element.properties.client_code,
                }
            )
            break;
    }
}
export default ConvertToGraph;