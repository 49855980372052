import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';


const FileSearchResultPage = (props: any) => {
    const closeModal = () => {
        props.setShowModal(false);
    };

    const rows = [
        '検索結果1', '検索結果2', '検索結果3', '検索結果4', '検索結果5', '検索結果6', '検索結果7',
    ];

    return (
        <Dialog
            open={props.open} //boolean
            onClose={closeModal} //boolean
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>検索結果一覧</DialogTitle>
            <DialogContentText >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContentText>
            <DialogActions>
                <Button onClick={closeModal} variant="contained" color="secondary">
                    閉じる
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default FileSearchResultPage


