import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useOktaAuth } from '@okta/okta-react';
import Service from '../domain/Service';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

interface UrlList {
  [fileName: string]: { url: string }
}

interface ServiceCardProps {
  urlList: UrlList,
  service: Service
}

const CenterDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function ServiceCard(props: ServiceCardProps) {
  const { authState } = useOktaAuth();
  const getThumbnailUrl = (fileName: string) => {
    return fileName in props.urlList ? props.urlList[fileName].url : '';
  }

  const fetchPamphletUrl = async (fileName: string) => {
    let openWindow = window.open();

    if (authState?.isAuthenticated) {
      const response = await fetch(`https://gzi7fmtuzb.execute-api.ap-northeast-1.amazonaws.com/pamphlets/${fileName}`, {
        headers: {
          Authorization: `Bearer ${authState?.idToken?.idToken}`
        }
      });
      openWindow!.location = await response.json();
    }
  }


  if (Object.keys(props.urlList).length > 0) {
    return (
      <Grid item>
      <Card sx={{ width:340, maxWidth: 340 }}>
        <CardActionArea onClick={() => fetchPamphletUrl(props.service.pamphlet_name)}>
          <CardMedia
            component="img"
            height="140"
            image={getThumbnailUrl(props.service.thumbnail_file_name)}
            alt={props.service.title}
          />
          <CardContent sx={{ height: 90 }}>
            <Typography gutterBottom variant="h5" component="div">
              {props.service.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.service.abstract}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      </Grid>
    );
  } else {
    return (
      <Grid item>
      <Card sx={{ width: 340}}>
        <CenterDiv>
          <CircularProgress />
        </CenterDiv>
      </Card>
      </Grid>
    );
  }
}