import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Spinner() {

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, color: "#1976d2" }}>NOW LOADING</div>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        </div>
    );
}
export default Spinner;