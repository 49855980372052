import { useOktaAuth } from '@okta/okta-react';

export function useOrganizationGraph(){
    const { authState } = useOktaAuth();
    const getNodeGraph: (node: string) => Promise<Array<any>| null> = async (node: string) => {
        const token = authState?.idToken?.idToken
        try{
            const res = await fetch(`https://${process.env.REACT_APP_API_DOMAIN_NAME}/api/nodes?name=${node}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            return await res.json()
        }catch(e){
            console.log(e)
            return null
        }
    }
    const getShortestPath: (from: string,to: string) => Promise<Array<any>| null> = async (from: string,to: string) => {
        const token = authState?.idToken?.idToken
        try{
            const res = await fetch(`https://${process.env.REACT_APP_API_DOMAIN_NAME}/api/shortest_path?from=${from}&to=${to}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            return await res.json()
        }catch(e){
            console.log(e)
            return null
        }
    }

    const getNodeInstance: (nodeType: string) => Promise<Array<any>| null> = async (nodeType: string) => {
        const token = authState?.idToken?.idToken
        try{
            const res = await fetch(`https://${process.env.REACT_APP_API_DOMAIN_NAME}/api/node_type?type_name=${nodeType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            return await res.json()
        }catch(e){
            console.log(e)
            return null
        }

    }

    return {
        getNodeGraph,
        getShortestPath,
        getNodeInstance
    }
}
