import Box from '@mui/material/Box';
import Contents from './Contents';
import Header from './Header';

const Portal = () => {
  return (
    <Box>
      <Header />
      <Contents />
    </Box>
  );
};

export default Portal;