import Graph from "react-graph-vis";
import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SimpleGraph from './SimpleGraph';
import ShortestPathGraph from './ShortestPathGraph';
import Node from "../domain/Node";
import GraphInterface from "../domain/Graph";
import { useOrganizationGraph } from "../hooks/relationship";
import { NodeNameList } from "../data/NodeNameList";
import Spinner from "./Spinner";

interface ConnectProps {
    style: any,
    searchFlag: string
}

function ConnectSearch(props: ConnectProps) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { getNodeGraph } = useOrganizationGraph()
    const { getShortestPath } = useOrganizationGraph()

    //labelsを2つ以上選択されている場合true
    const [isLabelSelectable, setIsLabelSelectable] = React.useState<boolean>(false);
    // ローディング
    const [isLoading, setIsLoading] = React.useState(false);


    //予測変換の値選択時にAPI実行され、グラフが表示される
    const singleHandleChange = async (value: string | null) => {
        if (value) {
            setIsLoading(true);
            const response: any = await getNodeGraph(value)
            setIsLoading(false);
            const simpleGraph: GraphInterface = SimpleGraph(response["records"])
            setGraph(simpleGraph)
        }
    };

    //予測変換の値選択時にAPI実行され、グラフが表示される
    const shortestPathHandleChange = async (value: string[] | null) => {
        if (value && value.length >= 2) {
            setIsLabelSelectable(true)
            setIsLoading(true);
            const response: any = await getShortestPath(value[0], value[1])
            setIsLoading(false);
            console.log(response)
            const shortestPathGraph: GraphInterface = ShortestPathGraph(response["records"])
            setGraph(shortestPathGraph)
        }
        else {
            setIsLabelSelectable(false)
        }
    };

    //×ボタン押したときにグラフが消される
    const onClear = () => {
        setGraph({ nodes: [], edges: [] })
        setIsLabelSelectable(false)
    };

    const [graph, setGraph] = React.useState<GraphInterface>({ nodes: [], edges: [] });

    //Graphの表示オプション
    const options = {
        height: "500px"
    };

    const [info, setNode] = React.useState<Node>({ type: "", id: "", label: "", name: "", department: "", manage_department: "", image_path: "" });

    const events = {
        select: function (event: any) {
            var { nodes } = event;
            if (nodes.length > 0) {
                const result = graph.nodes.find((obj: { id: any; }) => obj.id === event.nodes[0]);
                if (result) {
                    handleOpen();
                    setNode(result);
                }
            }

        }
    };

    const getEmployeeComp = (node: Node) => {
        return (
            <Box id={node.id} sx={props.style.modal} >
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Avatar alt="sample" src={node.image_path} sx={{ width: 56, height: 56 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography >名前: {node.name}</Typography>
                        <Typography >従業員番号: {node.employee_no}</Typography>
                        <Typography >役割職種: {node.grade}</Typography>
                        <Typography >勤務地: {node.work_location}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const getOrganizationComp = (node: Node) => {
        return (
            <Box sx={props.style.modal} id={node.id}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Avatar alt="sample" src={node.image_path} sx={{ width: 56, height: 56 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography >部門名: {node.name}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const getSystemComp = (node: Node) => {
        return (
            <Box sx={props.style.modal} id={node.id}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Avatar alt="sample" src={node.image_path} sx={{ width: 56, height: 56 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography >システム名: {node.name}</Typography>
                        <Typography >顧客名: {node.client_company}</Typography>
                        <Typography >顧客部門名: {node.client_department}</Typography>
                        <Typography >APコード: {node.ap_code}</Typography>
                        <Typography >BU名: {node.bu_name}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const getProjectComp = (node: Node) => {
        return (
            <Box sx={props.style.modal} id={node.id}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Avatar alt="sample" src={node.image_path} sx={{ width: 56, height: 56 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography >プロジェクト名: {node.name}</Typography>
                        <Typography >顧客名: {node.client_name}</Typography>
                        <Typography >BU名: {node.business_type}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const nodeInfo = info ?
        info.type === "Employee" ? getEmployeeComp(info) :
            info.type === "Organization" ? getOrganizationComp(info) :
                info.type === "System" ? getSystemComp(info) :
                    info.type === "Project" ? getProjectComp(info) :
                        <></> : <></>;

    let autoComplete: any;
    if (props.searchFlag === "single") {
        autoComplete =
            <div>
                <div style={{ marginBottom: 15 }}>※組織名・プロジェクト名・システム名・従業員名で検索できます</div>
                <Autocomplete
                    disablePortal
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: string | null, reason: AutocompleteChangeReason,) => { (reason !== "clear") ? singleHandleChange(value) : onClear(); }}
                    id="combo-box-demo"
                    options={NodeNameList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="キーワードで検索" />}
                />
            </div>
    } else if (props.searchFlag === "shortestPath") {
        autoComplete =
            <div>
                <div style={{ marginBottom: 3 }}>※キーワードを2つ選んでください</div>
                <div style={{ marginBottom: 15 }}>※キーワード同士の最短経路を検索できます</div>
                <Autocomplete
                    multiple
                    disablePortal
                    getOptionDisabled={() => isLabelSelectable}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: string[] | null, reason: AutocompleteChangeReason,) => { (reason !== "clear") ? shortestPathHandleChange(value) : onClear(); }}
                    id="combo-box-demo"
                    options={NodeNameList}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="2つのキーワードで検索" />}
                />
            </div>
    }

    return (
        <div>
            {autoComplete}
            {isLoading ? <Spinner /> : <Graph
                graph={graph}
                options={options}
                events={events}
                getNetwork={network => {
                    //  if you want access to vis.js network api you can set the state in a parent component using this property
                }}
            />}
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {nodeInfo}
            </Modal>
        </div>
    );
}

export default ConnectSearch;
