import Graph from "../domain/Graph";
import ConvertToGraph from "./ConvertToGraph";

function SimpleGraph(response: any) {

  const graph: Graph = {
    nodes: [],
    edges: [],
  }

  ConvertToGraph(response[0]._fields[2].labels[0], graph, response[0]._fields[2])
  for (const element of response) {
    const label = element._fields[0].labels[0];
    ConvertToGraph(label, graph, element._fields[0])
    graph.edges.push(
      {
        from: element._fields[1].startNodeElementId,
        to: element._fields[1].endNodeElementId,
      }
    )
  }
  // NodeのelementIdが重複しているNodeは削除する
  graph.nodes = graph.nodes.filter(
    //(nodes配列の要素、要素番号、nodes配列自体) => nodes配列の各要素に対して、nodeと同じidを持つ一番最初の要素(element)の要素番号を取得し、その番号がnodeのindexと等しければtrue
    (node, index, nodes) => nodes.findIndex(element => { return node.id === element.id }) === index
  );

  return graph
}
export default SimpleGraph;