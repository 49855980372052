import Link from '@mui/material/Link';

export default function FileSearchTest() {

  return (
    <div>
      <Link href="https://app.mural.co/t/nst4306/m/nst4306/1671665335414/63107cbccb83df2af3c32e0c0846cb2dd77dca34?wid=0-1673327063805">NST Connectとは</Link>
      <img src='./NSTConnectとは.png' alt="NSTConnect" width="100%" />
      <img src='./NSTConnect機能.png' alt="NSTConnect機能" width="100%" />
    </div>
  );
}