import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileSearchResultPage from './FileSearchResultPage';
import DemoAlert from './DemoAlert';

export default function FileSearchTest() {
  // FileSearchResultPageの表示の状態を定義する
  const [showModal, setShowModal] = React.useState(false);
  const ShowModal = () => {
    setShowModal(true);
  }

  return (
    <div>
      <DemoAlert/>
      <Typography gutterBottom variant="h6" component="div">
        ファイル検索機能
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField id="input-with-sx" label="Search..." variant="standard"
          //Enter押したときにカーソルを外す
          onKeyDown={e => {
            if (e.key === 'Enter') {
              //TODO: カーソルは外れてるけどエラー出てる。別の書き方があると思います。
              let obj: any = document.activeElement
              if (obj) {
                obj.blur();
              }
            }
          }}
        />
        <Button size="small" color="primary" variant="contained" onClick={ShowModal}>検索</Button>
      </Box>
      <FileSearchResultPage open={showModal} setShowModal={setShowModal}
      />
    </div>
  );
}